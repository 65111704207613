.navbar {
    a:hover, a:link, a:visited { 
        color: white;
    }
    .navbar-brand {
        color: white;
    }

    color: white;
    background-color: black;
    .nav-link {
        color: white;
        &.active {
            background-color: #ccc;
        }
    }

    .react-select {
        color: black;
    }
}
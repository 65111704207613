.tarot-card {
    text-align: center;
    
    .meaning {
        position: relative;
        border: 1px solid #eee;
        border-radius: 10px;
        padding: 5px;
        padding-top: 20px;
        font-size: 0.85em;
        white-space: pre-wrap;
        text-align: left;

        .close {
            position: absolute;
            right: 5px;
            top: 2px;
            font-size: 14px;
        }              
    }
    
    .card-placeholder {
        margin: 5px 0px;
        display: flex;
        justify-content: center;
    
        .shadow {
            padding: 10px;
            margin: 10px;
            border-radius: 10px;
            border-width: 1px;
            box-shadow: 10px 10px 10px #ddddddbf;
            &.invalid {
                border-color: rgb(255, 82, 82);
                box-shadow: 10px 10px 10px rgb(255, 169, 169) !important;
            }
        }

        img.card {
            border-radius: 10px;
            border-width: 1px;
            max-height: 300px;
            &.reversed {
                transform: rotate(180deg);
            }            
        }    
    }
}

.App {
  // text-align: center;
  margin: 0 auto;
  font-size: 1em;
  height: 100vh;
  display: flex;
  flex-direction: column;

  .container.main {
    margin-top: 20px;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0%;
  }
}
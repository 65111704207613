.three-cards {
    .self-meaning {
        margin: 0px 0px 20px 0px;
        textarea {
            width: 100%;
        }
    }

    .meaning {
        position: relative;
        border: 1px solid #eee;
        border-radius: 10px;
        padding: 5px;
        padding-top: 20px;
        font-size: 0.85em;
        white-space: pre;
        text-align: left;
        white-space: pre-wrap;

        .close {
            position: absolute;
            right: 5px;
            top: 2px;
            font-size: 14px;
        }              
    }
}

footer {
    margin-top: 20px;
    padding: 10px;
    background-color: #000;
    color: white;
    a {
      color: white;
    }

    .footer {
        display: flex;
        justify-content: space-between;
    }
}